<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-list>
    <template v-for="(answer, index) in sortedResponses">
      <v-list-item :key="index" class="px-2">
        <v-list-item-avatar class="my-1 mr-2">
          <v-avatar size="24" color="secondary" class="white--text">{{ index + 1 }}</v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle class="text-wrap">
            {{ answer.surveyElement.surveyQuestion.label }} : <span class="font-weight-bold">{{ response(answer) }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="$auth.granted('role_professional')">
          <font-awesome-icon v-if="answer.surveyAlerts[0]" :icon="['fad', 'exclamation-triangle']" size="1x"
                             :class="alertTypeToColor(answer.surveyAlerts[0].level)"
          />
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
  import {deepCopy} from '@/utils/object'

  export default {
    name: 'AnswersList',
    props: {
      answers: Array
    },
    data() {
      return {
        data: deepCopy(this.answers)
      }
    },
    computed: {
      response() {
        return answer => {
          let response = answer.response
          if (answer.surveyElement.surveyQuestion.type === 'boolean') {
            response = answer.response === '1' ? 'Oui' : 'Non'
          } else if (answer.surveyElement.surveyQuestion.type === 'choice') {
            const choiceResponses = []
            answer.surveyChoices.map(choice => {
              choiceResponses.push(choice.label)
            })

            response = choiceResponses.join(', ')
          }
          return response
        }
      },
      sortedResponses() {
        return [...this.data].sort((a, b) => {
          if (this.$dayjs(a.creationDate) < this.$dayjs(b.creationDate)) {
            return -1
          }
          if (this.$dayjs(a.creationDate) > this.$dayjs(b.creationDate)) {
            return 1
          }
          return 0
        })
      }
    },
    methods: {
      alertTypeToColor(type) {
        switch (type) {
          case 'low':
            return 'yellow--text text--darken-2'
          case 'medium':
            return 'orange--text text--darken-3'
          case 'high':
            return 'red--text text--darken-2'
        }
      }
    }
  }
</script>
