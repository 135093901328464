<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-dialog :value="true" max-width="600" transition="dialog-bottom-transition" persistent>
    <v-form ref="surveyForm" class="mx-auto" lazy-validation @submit.prevent="submit">
      <v-card>
        <v-icon color="white" small class="close-submit-dialog" @click="close">$close</v-icon>
        <v-list class="pa-0">
          <v-img class="p-abs img-header" src="@/assets/img/login-default-bg.png" min-width="250" width="100%" max-height="70px" />
          <div class="title-header">
            <v-list-item-title class="text-h5 font-weight-light white--text px-5 p-rel white--text pt-2 w-100">
              {{ patientSelected.lastName }} {{ patientSelected.firstName }}
              <v-chip v-if="$auth.granted('role_professional')" small color="primary" class="patient-phone">{{ patientSelected.phone || patientSelected.mobilePhone || 'Non renseigné' }}</v-chip>
            </v-list-item-title>
            <v-list-item-subtitle class="text-caption font-alt mb-1 px-5 white--text p-rel">
              né(e) le {{ $dayjs(patientSelected.birthDate).format('DD/MM/YYYY') }}
            </v-list-item-subtitle>
          </div>
          <div class="splitter" />
          <v-alert v-if="$auth.granted('role_professional') && surveyAcknowledgmentDate && acknowledgmentAuthor"
                   type="success"
                   tile
                   dense
          >
            Acquitté le {{ $dayjs(surveyAcknowledgmentDate).format('DD/MM/YYYY') }} par {{ acknowledgmentAuthor.firstName }} {{ acknowledgmentAuthor.lastName }}
          </v-alert>
          <div v-if="$auth.granted('role_professional')" class="pa-4 p-rel">
            <div class="font-alt text-body-1 font-weight-light mb-3">Notes :</div>
            <v-textarea v-model="model.content" hide-details="auto" rows="1" auto-grow dense outlined class="align-center" label="Laisser une note">
              <template v-slot:append-outer>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn x-small v-bind="attrs" color="success" height="36px" v-on="on" @click.prevent="saveNote">
                      <font-awesome-icon :icon="['fal', 'save']" size="2x" />
                    </v-btn>
                  </template>
                  <span>Enregistrer ma note</span>
                </v-tooltip>
              </template>
            </v-textarea>
            <template v-if="notes">
              <v-list-item v-for="(note, index) in notes" :key="index" class="px-0 mt-3">
                <v-list-item-avatar size="38" class="mr-1">
                  <font-awesome-icon :icon="['fad', 'sticky-note']" size="2x" class="yellow--text text--darken-3" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="white-space-inherit">{{ note.content }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ note.author.identity.firstName }} {{ note.author.identity.lastName }}
                    <span class="font-weight-bold">le {{ $dayjs(note.creationDate).format('LLL') || 'Non renseignée' }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </div>
          <v-divider v-if="$auth.granted('role_professional')" />
          <div class="font-alt text-body-1 font-weight-light mb-2 px-4 pt-4">Résumé du questionnaire :</div>
          <answers-list :answers="surveyResponse.surveyElementResponses" />
        </v-list>
        <v-textarea v-if="canAcquit" v-model="comment" outlined dense hide-details="auto" label="Ajouter un commentaire d'acquittement" class="px-4 py-2" />
        <div v-if="$auth.granted('role_professional') && surveyStatus === 'acknowledged' && surveyComment">
          <v-divider />
          <div class="font-alt text-body-1 font-weight-light mb-2 px-4 pt-4">Commentaire :</div>
          <div class="ma-4 text-body-2 text-pre-wrap">{{ surveyComment }}</div>
        </div>

        <v-card-actions class="px-3">
          <v-spacer />
          <v-btn small rounded text @click="close">
            Fermer
          </v-btn>
          <v-btn v-if="canAcquit" small rounded color="success" type="submit" :loading="submitting">
            Acquitter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  import AnswersList from '@/modules/surveys/components/responses/AnswersList'
  import api from '@/services/api'
  import * as notes from '@/modules/surveys/api/notes'

  export default {
    name: 'SurveyDetailsDialog',
    components: {
      AnswersList
    },
    props: {
      surveyResponse: Object,
      patientSelected: Object,
      surveyStatus: String,
      surveyComment: String,
      surveyAcknowledgmentDate: String,
      surveyAcknowledgmentAuthorIri: String,
      staySurveyIri: String,
      staySurveyId: String,
      endDate: String
    },
    data() {
      return {
        acknowledgmentAuthor: {
          firstName: '',
          lastName: ''
        },
        comment: '',
        model : {
          content: '',
          staySurvey: this.staySurveyIri
        },
        content: '',
        notes: [],
        submitting : false,
        loading: true
      }
    },
    computed: {
      canAcquit() {
        return this.$auth.granted('role_professional') && this.surveyStatus === 'complete' && !(this.$dayjs(this.endDate) < this.$dayjs())
      }
    },
    created() {
      if(this.$auth.granted('role_professional')) {
        this.getSurveyNote()
        if(this.surveyAcknowledgmentAuthorIri) {
          this.getSurveyAcknowledgmentAuthor()
        }
      }
    },
    methods: {
      async saveNote() {
        this.loading = true
        try {
          await notes.create(this.model)
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.loading = false
          this.model.content = ''
          await this.getSurveyNote()
        }
      },
      async getSurveyNote() {
        this.loading = true
        try {
          await notes.list({
            params: {
              staySurvey: this.staySurveyId
            }
          }).then((response) => {
            this.notes = response.data['hydra:member']
          })
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.loading = false
        }
      },
      async getSurveyAcknowledgmentAuthor() {
        this.loading = true
        try {
          await api.get(this.surveyAcknowledgmentAuthorIri).then((response) => {
            this.acknowledgmentAuthor = { firstName: response.data.identity.firstName, lastName: response.data.identity.lastName }
          })
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.loading = false
        }
      },
      close() {
        this.$emit('close')
      },
      async submit() {
        if (!this.$refs.surveyForm.validate()) {
          return false
        }
        this.submitting = true
        this.model = {
          surveyResponseStatus: 'acknowledged',
          surveyResponseAcknowledgmentComment: this.comment
        }
        await api.put(this.staySurveyIri, this.model)
        this.submitting = false
        this.$emit('acknowledge', this.staySurveyId)
        this.close()
      }
    }
  }
</script>
